/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { Pagination } from "@material-ui/lab";
import {
  Container,
  CircularProgress,
  Paper,
  Grid,
  Typography,
  Button,
  withWidth,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
  Checkbox,
  FormGroup,
  IconButton,
} from "@material-ui/core";
import useStyles from "./styles";
import history from "../../../services/history";
import logo from "../../../assets/logo.svg";
import api from "../../../services/api";
import { toast } from "react-toastify";

import { Create } from "@material-ui/icons";

function Dashboard() {
  const me = useSelector((state) => state.user.me);
  const [loading, setLoading] = React.useState(false);
  const [tasks, setTasks] = React.useState([]);
  const [superT, setSuperT] = React.useState(false);
  const [resource, setResource] = React.useState(
    me.role === "admin" && !me.permissons ? true : false
  );
  const [pagination, setPagination] = React.useState({
    page: 1,
    totalPages: 1,
  });
  const classes = useStyles();

  function handle(e) {
    history.push(`/task/${e._id}`);
  }

  async function getTasks() {
    try {
      setLoading(true);
      const response = await api.get("/agent/user");

      setTasks(response.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Ocorreu algum erro, tente novamente mais tarde.");
    }
  }

  async function getSuper(page) {
    try {
      setLoading(true);
      const response = await api.get("/answers", {
        params: {
          problem: true,
          page,
          perPage: 10,
        },
      });

      setTasks(response.data.data);
      setPagination({
        page: response.data.page,
        totalPages: response.data.totalPages,
      });

      console.log(response.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Ocorreu algum erro, tente novamente mais tarde.");
    }
  }

  async function getRecorrection(page) {
    try {
      setLoading(true);
      const response = await api.get("/answers", {
        params: {
          resource: true,
          page,
          perPage: 10,
        },
      });

      setTasks(response.data.data);
      setPagination({
        page: response.data.page,
        totalPages: response.data.totalPages,
      });

      console.log(response.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Ocorreu algum erro, tente novamente mais tarde.");
    }
  }

  function name() {
    if (superT) return " com problemas.";

    if (resource) return "- recorreção.";

    return "";
  }

  function handleChange(event, value) {
    if (superT) {
      getSuper(value);
    } else {
      getRecorrection(value);
    }
  }

  React.useEffect(() => {
    if (superT) {
      getSuper(1);
    } else if (resource) {
      getRecorrection(1);
    } else {
      getTasks();
    }
  }, [superT, resource]);

  return (
    <Container maxWidth="lg">
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          {loading ? (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} sm={6}>
                <Typography
                  className={classes.title}
                >{`Tarefas ${name()}`}</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  {me.permissons && me.permissons.super && (
                    <Grid item xs={12} sm={6}>
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="Super"
                          control={
                            <Checkbox
                              color="primary"
                              checked={superT}
                              onChange={(event) => {
                                setTasks([]);
                                if (event.target.checked) {
                                  setResource(!event.target.checked);
                                }
                                setSuperT(event.target.checked);
                              }}
                            />
                          }
                          label="com problemas"
                          labelPlacement="com problemas"
                        />
                      </FormGroup>
                    </Grid>
                  )}

                  {me.role === "admin" && me.permissons && (
                    <Grid item xs={12} sm={6}>
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="Super"
                          control={
                            <Checkbox
                              color="primary"
                              checked={resource}
                              onChange={(event) => {
                                setTasks([]);
                                if (event.target.checked) {
                                  setSuperT(!event.target.checked);
                                }

                                setResource(event.target.checked);
                              }}
                            />
                          }
                          label="Recorreção"
                          labelPlacement="Recorreção"
                        />
                      </FormGroup>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {me.permissons && !resource ? (
                <>
                  {superT ? (
                    <>
                      <Grid item xs={12}>
                        <TableContainer>
                          <Table
                            className={classes.table}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Nome</TableCell>
                                <TableCell align="right">Tipo</TableCell>
                                <TableCell align="right">Equipe</TableCell>
                                <TableCell align="right">Região</TableCell>
                                <TableCell align="right">Ação</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {tasks.map((item) => (
                                <TableRow key={item._id}>
                                  <TableCell component="th" scope="row">
                                    {item.task.title}
                                  </TableCell>
                                  <TableCell align="right">
                                    {item.task.type}
                                  </TableCell>
                                  <TableCell align="right">
                                    {" "}
                                    {item.team?.name}
                                  </TableCell>
                                  <TableCell align="right">
                                    {item.team?.region}
                                  </TableCell>
                                  <TableCell align="right">
                                    <IconButton
                                      onClick={() =>
                                        history.push(
                                          `/task/${item.task._id}/${item._id}`
                                        )
                                      }
                                    >
                                      <Create tyle={{ color: "#483699" }} />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </>
                  ) : (
                    <>
                      {tasks.map((task) => (
                        <Grid item xs={12} sm={4} md={3}>
                          <Button fullWidth onClick={() => handle(task)}>
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <img src={logo} className={classes.img} />
                              </Grid>
                              <Grid item xs={12}>
                                <Typography className={classes.taskName}>
                                  {`Desafio ${task.number}`}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Button>
                        </Grid>
                      ))}
                    </>
                  )}
                </>
              ) : (
                <>
                  {resource ? (
                    <Grid item xs={12}>
                      <TableContainer>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>Nome</TableCell>
                              <TableCell align="right">Tipo</TableCell>
                              <TableCell align="right">Equipe</TableCell>
                              <TableCell align="right">Região</TableCell>
                              <TableCell align="right">Ação</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {tasks.map((item) => (
                              <TableRow key={item._id}>
                                <TableCell component="th" scope="row">
                                  {item.task.title}
                                </TableCell>
                                <TableCell align="right">
                                  {item.task.type}
                                </TableCell>
                                <TableCell align="right">
                                  {" "}
                                  {item.team.name}
                                </TableCell>
                                <TableCell align="right">
                                  {item.team.region}
                                </TableCell>
                                <TableCell align="right">
                                  <IconButton
                                    onClick={() =>
                                      history.push(
                                        `/task/${item.task._id}/${item._id}`
                                      )
                                    }
                                  >
                                    <Create tyle={{ color: "#483699" }} />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  ) : (
                    <Typography>
                      Você não tem Permissão para corrigir recorrigir tarefas
                    </Typography>
                  )}
                </>
              )}

              {(superT || resource) && (
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Pagination
                    count={pagination.totalPages}
                    page={pagination.page}
                    onChange={handleChange}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Paper>
    </Container>
  );
}

export default withWidth()(Dashboard);
