import React from "react";
import ReactDOMServer from "react-dom/server";
import {
  Paper,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  withStyles,
  Button,
  CircularProgress,
} from "@material-ui/core";

import { ReportProblem } from "@material-ui/icons";
import api from "../../../../services/api";
import history from "../../../../services/history";
import useStyles from "./styles";
import { toast } from "react-toastify";

function validURL(str) {
  if (String(str).includes("http")) {
    return true;
  } else {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );

    return !!pattern.test(str);
  } // fragment locator
}

const CustomRadio = withStyles({
  root: {
    color: "#483699",
    "&$checked": {
      color: "#483699",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const makelink = (text) => {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    return ReactDOMServer.renderToString(
      <Button link href={url} target="_blank">
        link
      </Button>
    );
  });
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
};

function Task({ data, att, id }) {
  const classes = useStyles();
  const [items, setItems] = React.useState(
    data ? data.taskItens.map((item) => item.grade || 0) : []
  );
  const [text, setText] = React.useState(data ? data.agentAlert || "" : "");
  const [text2, setText2] = React.useState(data ? data.resourceText || "" : "");
  const [change, setChange] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  function allRight() {
    setItems([...data.taskItens].map((task) => task.taskItem.grade));
    setText("Parabéns, sua equipe acertou tudo!");
  }

  function allWrong() {
    setItems(items.map((item) => 0));
  }

  function mark(value, index) {
    let newVector = items;
    newVector[index] = value;
    setItems(newVector);

    setChange(!change);
  }

  async function submit() {
    const preArray = items;

    let query = {
      answer: data._id,
      alert: text,
      taskItens: preArray.map((item) => ({
        grade: Number(item),
      })),
    };

    try {
      setLoading(true);

      await api.post("/agent", query);

      toast.success("Tarefa corrigada com sucesso.");
      setLoading(false);

      if (!id) {
        att();
      } else {
        history.push("/dashboard");
      }
    } catch (error) {
      toast.error("Ocorreu algum erro, tente novamente mais tarde.");
      setLoading(false);
    }
  }

  async function report() {
    try {
      setLoading(true);

      await api.put(`/agent/${data._id}`);

      toast.success("Tarefa reportada com sucesso.");
      setLoading(false);

      att();
    } catch (error) {
      toast.error("Ocorreu algum erro, tente novamente mais tarde.");
      setLoading(false);
    }
  }

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item sm={8} xs={12} style={{ borderRight: "1px solid #483699" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.title}>{`Tarefa ${
                    data && data.status === "w2_resource" ? "- Recorreção" : ""
                  }`}</Typography>

                  {data?.agentId && (
                    <Typography>Corretor: {data.agentId}</Typography>
                  )}
                </Grid>

                <Grid item>
                  <Grid container spacing={2}>
                    {!id && (
                      <Grid item xs={12} sm={8} md={9}>
                        <Button
                          className={classes.btn1}
                          onClick={() => report()}
                        >
                          <ReportProblem
                            style={{ color: "#483699", marginRight: 10 }}
                          />
                          Reportar problema
                        </Button>
                      </Grid>
                    )}

                    <Grid item xs={12} sm={4} md={3}>
                      <Button
                        className={classes.btn1}
                        onClick={() => history.push("/dashboard")}
                      >
                        Sair
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.subTitle}>
                {data && `Desafio ${data.task.number} - ${data.task.title}`}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.label}>
                Itens e Respostas
              </Typography>
            </Grid>

            {data &&
              [...data.taskItens].map((task, index) => (
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography className={classes.taskTitle}>
                        {`${Number(index) + 1} - ${task.taskItem.agentsText}`}
                      </Typography>
                    </Grid>

                    {(task.taskItem.camp || task.answer) && (
                      <Grid item xs={12}>
                        <Typography
                          className={classes.taskAnswer}
                          dangerouslySetInnerHTML={{
                            __html: `resposta: ${makelink(task.answer)}`,
                          }}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="gender"
                          name="gender1"
                          value={items[index]}
                          onChange={(event) =>
                            mark(Number(event.target.value), index)
                          }
                        >
                          <Grid container spacing={2} wrap="nowrap">
                            <Grid item>
                              <FormControlLabel
                                value={task.taskItem.grade}
                                control={<CustomRadio />}
                                label="Certo"
                              />
                            </Grid>

                            <Grid item>
                              <FormControlLabel
                                value={0}
                                control={<CustomRadio />}
                                label="Errado"
                              />
                            </Grid>
                          </Grid>
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>

        <Grid item xs={5} sm={4} xs={12}>
          <Grid
            style={{ height: "100%" }}
            container
            direction="column"
            justify="space-between"
            alignItems="stretch"
          >
            <Grid item>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography className={classes.title}>
                    Opções rápidas
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    fullWidth
                    className={classes.btn1}
                    onClick={() => allRight()}
                  >
                    Marcar todos corretos
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    fullWidth
                    className={classes.btn1}
                    onClick={() => allWrong()}
                  >
                    Marcar todos errados
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography className={classes.label}>
                        Descrição da resposta
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <textarea
                        value={text}
                        className={classes.input}
                        onChange={(e) => setText(e.target.value)}
                        name="zeroReason"
                        label="Descricao da correcao"
                        multiline
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography className={classes.label}>
                        Motivo de pedido
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <textarea
                        value={text2}
                        className={classes.input}
                        disabled
                        name="zeroReason"
                        label="Descricao da correcao"
                        multiline
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item style={{ marginTop: 20 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <Button
                      fullWidth
                      className={classes.btn1}
                      onClick={() => submit()}
                    >
                      Enviar correção
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Task;
